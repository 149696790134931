.App {
    background-color: rgb(43, 48, 53)
}


.NavBar {
    z-index: 2;
}

.container {
    justify-content: center !important
}

.navbar-brand {
    margin-left: 2rem;
}

.dropdown-menu.show {
    text-align: center;
}

.logo-img {
    background-image: url('/public/bfalogo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


.bg-img-1 {
    background-image: url('/public/blackforestoverhead.WebP');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-2 {
    background-image: url('/public/faceofbuilding.WebP');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-img-3 {
    background-image: url('/public/sideofbuilding.WebP');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.carousel-image {
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
}

.row {
    text-align: center;
    margin-top: 1rem;
    max-width: 100vw;
}

.container-marketing {
    background-color: rgb(43, 48, 53);
    margin-top: 3rem;
    margin-bottom: 7rem;
}

.testimonial-img-1,
.testimonial-img-2,
.testimonial-img-3 {
    background-image: url('/public/google5star.png');
    background-repeat: no-repeat;
    background-size: cover;

}

h2,
h3,
p {
    color: white;
}

.featurette-image-1 {
    background-image: url('/public/featuretteimage1.WebP');
    background-size: contain;
    background-repeat: no-repeat;
}

.featurette-image-2 {
    background-image: url('/public/alignmentmachine.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.featurette-image-3 {
    background-image: url('/public/fluidservice.WebP');
    background-size: contain;
    background-repeat: no-repeat;
}

.col-md-4 {
    color: white;
}


.col-md-7 {
    margin-top: 3rem;
}

.about-us {
    text-align: center;
}

.about-us-img {
    background-image: url('/public/frontsign.WebP');
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
    margin-top: 2rem;
}

.btn-about {
    text-decoration: none;
}

.card {
    color: white;
    text-align: center;
}

.card-body {
    background-color: rgb(43, 48, 53);
}

.py-3 {
    color: white;
    justify-content: center;
}

#linkedin {
    text-decoration: none;
}

@media (max-width: 500px) {
    .row.g-5 {
        padding-right: 0;
        margin: 0;
    }

    .col-md-4 {
        padding-left: 0px;
        margin: 0;
    }

    .d-flex {
        justify-content: center;
    }
}

@media (max-width: 700px) {
    .row {
        margin: 0;
    }

    .col-md-4 {
        margin-top: 0;
        padding: 0;
    }
}


@media (min-width: 700px) {

    .featurette-container {
        margin-top: 8rem;
        padding-left: 0;
    }

    .col-md-5 {
        align-self: center;
    }
}